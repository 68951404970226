
    <template>
  <table width="100%">
    <tbody>
      <tr>
        <td>
          <button class="btn btn-sm p-2"><p class="m-0">normal</p></button>
        </td>
        <td>
          <button class="btn btn-sm p-2"><p class="m-0">2 số</p></button>
        </td>
        <td>
          <button class="btn btn-sm p-2"><p class="m-0">3 Số</p></button>
        </td>
        <td>
          <div>
            <button class="circle m-2" v-for="(n, i) in 9" :key="i">
              <p class="m-1">{{ i + 1 }}</p>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  components: {},
  props: {},
  computed: {},
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style>
.circle {
  border-radius: 24px;
  background-color: rgb(0, 11, 73);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}
</style>
