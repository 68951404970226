<template>
  <div id="app">
    <div id="container">
      <Header />
      <Home />
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";

import Header from "./views/Header.vue";
import Home from "./views/Home.vue";

export default {
  name: "app",

  components: { Header, Home },
  data() {
    return {};
  },

  created() {},
  methods: {},
};
</script>

<style>
html {
  background: #f8f8f8;
}
#app {
  font-family: "Prompt", sans-serif;
  background: #f8f8f8;
}
#header {
  margin: 0px auto;
  width: 800px;
  text-align: center;
}
.text-box {
  background-color: rgb(0, 40, 104);
  color: white;
}
#container {
  width: 60%;
  /*height: 550px; */
  margin: 0px auto;
  padding: 0px;
}
h1 {
  font-size: 24px !important;
}
h4 {
  font-size: 14px !important;
}
.col-lg-12 {
  display: block;
}
@media (max-width: 575.98px) {
  #container {
    width: 100%;
  }
}
</style>
